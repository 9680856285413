import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { Header } from "./header";
import { Question } from "./question";
import { setByValue } from "./questionSlice";
import { setByValue as setCurrentQuestionByValue } from "./currentQuestionSlice";

import { Arrow } from "../components/arrow";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const Game = () => {
  const navigate = useNavigate();
  const isInitialMount = useRef(true);
  const [mode, setMode] = useState(null);

  const currentQuestionIndex = useSelector(
    (state) => state.currentQuestion.value
  );
  const questions = useSelector((state) => state.question.value);
  const dispatch = useDispatch();

  const setQuestion = (direction) => {
    if (mode === "description") {
      // Mark question as used.
      dispatch(
        setByValue(
          questions.map((item, index) => {
            if (index === currentQuestionIndex) {
              return { ...item, used: true };
            }

            return item;
          })
        )
      );

      setMode(null);
    }

    let newIndex = currentQuestionIndex;

    if (direction === "+") {
      newIndex++;
      if (newIndex > questions.length - 1) {
        newIndex = 0;
      }
    } else {
      newIndex--;
      if (newIndex === -1) {
        newIndex = questions.length - 1;
      }
    }

    dispatch(setCurrentQuestionByValue(newIndex));
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      // Your useEffect code here to be run on update
      if (
        questions.filter((q) => q.used).length > 0 &&
        questions.filter((q) => q.used).length % 5 === 0 &&
        questions.filter((q) => q.used).length !== questions.length
      ) {
        navigate("/spielen/fazit");
      }

      if (questions.filter((q) => q.used).length === questions.length) {
        navigate("/spielen/fazit/final");
      }
    }
  }, [questions]);

  return (
    <div>
      {mode !== "reaction" && <Header />}

      <div
        className={classNames({
          "mx-10 mt-20 flex items-center justify-between": true,
        })}
      >
        {mode === null || mode === "description" ? (
          <Arrow
            onClick={() => setQuestion("-")}
            className={classNames({
              "ml-10 h-auto w-[9rem] cursor-pointer": true,
              "-mb-72": mode === null,
            })}
            direction="left"
          />
        ) : null}

        <Question
          q={questions[currentQuestionIndex]}
          setMode={setMode}
          mode={mode}
          next={setQuestion}
        />

        {mode === null || mode === "description" ? (
          <Arrow
            onClick={() => setQuestion("+")}
            className={classNames({
              "mr-10 h-auto w-[9rem] cursor-pointer": true,
              "-mb-72": mode === null,
            })}
          />
        ) : null}
      </div>
    </div>
  );
};
