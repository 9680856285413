import PropTypes from "prop-types";

export const Smile = (props) => (
  <svg
    onClick={props.onClick}
    className={props.className ?? props.className}
    width="134"
    height="134"
    viewBox="0 0 134 134"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M67.302 132.212C103.259 132.212 132.408 103.063 132.408 67.1062C132.408 31.1491 103.259 2 67.302 2C31.3449 2 2.1958 31.1491 2.1958 67.1062C2.1958 103.063 31.3449 132.212 67.302 132.212Z"
      fill={props.fill ? props.fill : "#46877F"}
      stroke="#0E0E10"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M105.373 67.1061C105.237 77.1125 101.167 86.6631 94.0432 93.6912C86.9192 100.719 77.3144 104.66 67.307 104.66C57.2997 104.66 47.6948 100.719 40.5708 93.6912C33.4468 86.6631 29.3766 77.1125 29.241 67.1061"
      stroke="#0E0E10"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M49.5583 52.6619C52.9833 52.6619 55.7599 49.8853 55.7599 46.4603C55.7599 43.0352 52.9833 40.2587 49.5583 40.2587C46.1332 40.2587 43.3567 43.0352 43.3567 46.4603C43.3567 49.8853 46.1332 52.6619 49.5583 52.6619Z"
      fill="#0E0E10"
    />
    <path
      d="M85.0473 52.6619C88.4723 52.6619 91.2489 49.8853 91.2489 46.4603C91.2489 43.0352 88.4723 40.2587 85.0473 40.2587C81.6223 40.2587 78.8457 43.0352 78.8457 46.4603C78.8457 49.8853 81.6223 52.6619 85.0473 52.6619Z"
      fill="#0E0E10"
    />
  </svg>
);

Smile.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  fill: PropTypes.string,
};
