import PropTypes from "prop-types";

export const Evil = (props) => (
  <svg
    onClick={props.onClick}
    className={props.className ?? props.className}
    width="135"
    height="135"
    viewBox="0 0 135 135"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M67.6483 133C103.797 133 133.101 103.696 133.101 67.5475C133.101 31.399 103.797 2.09497 67.6483 2.09497C31.4999 2.09497 2.1958 31.399 2.1958 67.5475C2.1958 103.696 31.4999 133 67.6483 133Z"
      fill="#D5654D"
      stroke="#0E0E10"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M105.914 105.812C105.914 95.6637 101.883 85.9309 94.7068 78.7548C87.5308 71.5787 77.7979 67.5472 67.6494 67.5472C57.5009 67.5472 47.7681 71.5787 40.592 78.7548C33.416 85.9309 29.3845 95.6637 29.3845 105.812"
      stroke="#0E0E10"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M49.8093 53.0256C53.2525 53.0256 56.0439 50.2343 56.0439 46.791C56.0439 43.3478 53.2525 40.5565 49.8093 40.5565C46.366 40.5565 43.5747 43.3478 43.5747 46.791C43.5747 50.2343 46.366 53.0256 49.8093 53.0256Z"
      fill="#0E0E10"
    />
    <path
      d="M85.4897 53.0256C88.933 53.0256 91.7243 50.2343 91.7243 46.791C91.7243 43.3478 88.933 40.5565 85.4897 40.5565C82.0464 40.5565 79.2551 43.3478 79.2551 46.791C79.2551 50.2343 82.0464 53.0256 85.4897 53.0256Z"
      fill="#0E0E10"
    />
  </svg>
);

Evil.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
