import { createSlice } from "@reduxjs/toolkit";

import content from "./../content.json";

export const currentQuestionSlice = createSlice({
  name: "currentQuestion",
  initialState: {
    value: 0,
  },
  reducers: {
    setByValue: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setByValue } = currentQuestionSlice.actions;

export default currentQuestionSlice.reducer;
