import { ThumbsUp } from "../../game/reactions/thumbs-up";
import { ThumbsDown } from "../../game/reactions/thumbs-down";

export const Page2 = () => {
  return (
    <div>
      <header className="mx-auto flex max-w-[100rem] items-baseline justify-center py-16 px-10 pb-10">
        <h3 className="mt-4 mb-8 mr-10 text-center font-Monument text-[100px] font-bold leading-tight text-freiburg-red">
          3
        </h3>
        <p className="mb-8 text-center text-6xl font-bold leading-tight">
          Liegst du richtig oder falsch?
        </p>
      </header>

      <div className="mx-auto grid max-w-[84rem] grid-flow-col grid-cols-2 gap-4">
        <div className="border-2 border-freiburg-black py-28">
          <ThumbsUp className="mx-auto block h-auto max-w-[16rem] -scale-x-100 " />
        </div>
        <div className="border-2 border-freiburg-black py-28">
          <ThumbsDown className="mx-auto block h-auto max-w-[16rem]" />
        </div>
      </div>
    </div>
  );
};
