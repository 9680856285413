import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "../../components/button";
import { ThumbsUp } from "../../components/thumbs-up";
import { Arrow } from "../../components/arrow";
import { Page0 as StartHelp0 } from "./page-0";
import { Page00 as StartHelp00 } from "./page-00";
import { Page1 as StartHelp1 } from "./page-1";
import { Info } from "../../components/info";
import { Page0 } from "../in-game-help/page-0";
import { Page1 } from "../in-game-help/page-1";
import { Page2 } from "../in-game-help/page-2";
import { Page3 } from "../in-game-help/page-3";
import { Page4 } from "../in-game-help/page-4";

export const Help = () => {
  const [page, setPage] = useState(0);
  const maxPage = 6;

  let navigate = useNavigate();

  return (
    <>
      <header className="mt-14">
        <h1 className="text-center font-Monument text-[115px] font-extrabold leading-tight">
          Rassistisch oder nicht?
        </h1>
        <h2 className="text-center text-[84px] font-bold leading-tight">
          Teste doch mal deine Alltagssprache.
        </h2>
      </header>

      <main className="mx-14 mt-12 h-[70%] rounded-[45px] border-[6px] border-black bg-freiburg-white">
        <div className="relative h-full">
          {page !== 0 ? (
            <Arrow
              direction="left"
              className="absolute left-16 top-1/2 h-auto max-w-[6rem] -translate-y-1/2 cursor-pointer"
              onClick={() => setPage(page - 1)}
            />
          ) : null}

          {(() => {
            switch (page) {
              case 0:
                return <StartHelp0 />;
              case 1:
                return <StartHelp00 />;
              case 2:
                return <Page0 />;
              case 3:
                return <Page1 />;
              case 4:
                return <Page2 />;
              case 5:
                return <Page3 />;
              case 6:
                return <Page4 />;
              case 7:
                return <StartHelp1 />;
            }
          })()}

          {page !== maxPage ? (
            <>
              {page === 1 ? (
                <Info
                  onClick={() => setPage(page + 1)}
                  className="absolute top-[50%] right-48 h-auto max-w-[7rem] -translate-y-1/2 cursor-pointer"
                />
              ) : null}

              <Arrow
                direction="right"
                className="absolute right-16 top-[50%] h-auto max-w-[6rem] -translate-y-1/2 cursor-pointer"
                onClick={() => setPage(page + 1)}
              />
            </>
          ) : null}
        </div>

        {page === 1 || page === 6 ? (
          <Button
            className="relative -mt-20 bg-freiburg-yellow font-Monument"
            onClick={() => navigate("/spielen")}
          >
            Los geht's
            <ThumbsUp className="absolute -right-[5rem] -top-[5rem] h-auto max-w-[7.5rem]" />
          </Button>
        ) : null}
      </main>
    </>
  );
};
