export const ThumbsUp = (props) => (
  <svg
    className={props.className ?? props.className}
    width="139"
    height="220"
    viewBox="0 0 139 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M130.502 132.332L119.658 122.572L125.896 26.2368C127.141 15.016 121.021 5.13484 112.211 4.08892C103.401 3.043 95.2505 11.3461 94.0048 22.5669L87.975 81.8175L66.0134 23.3467C61.7258 12.9334 51.5709 7.31843 43.447 10.7957C35.3231 14.2729 32.1186 25.5395 36.4152 35.9528L55.7412 82.8542C44.8723 85.1499 34.7503 90.1994 26.3146 97.5339H3.09826L3.43224 156.186V141.83C3.09826 97.5339 3.09562 146.345 3.09826 148.61C2.99606 157.5 4.63044 166.322 7.90674 174.565C11.183 182.808 16.0361 190.308 22.1847 196.631C28.3334 202.954 35.6554 207.974 43.7265 211.4C51.7977 214.827 60.4575 216.591 69.2043 216.591C77.9511 216.591 86.6109 214.827 94.682 211.4C102.753 207.974 110.075 202.954 116.224 196.631C122.373 190.308 127.226 182.808 130.502 174.565C133.778 166.322 135.417 157.5 135.315 148.61L130.502 132.332Z"
      fill="#46877F"
      stroke="#0E0E10"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M34.2046 90.2675C34.2046 81.2828 27.2402 73.9993 18.6492 73.9993C10.0582 73.9993 3.09375 81.2828 3.09375 90.2675V109.582C3.09375 118.567 10.0582 125.851 18.6492 125.851C27.2402 125.851 34.2046 118.567 34.2046 109.582V90.2675Z"
      fill="#46877F"
      stroke="#0E0E10"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M65.315 87.6752C65.315 78.6905 58.3506 71.407 49.7595 71.407C41.1685 71.407 34.2041 78.6905 34.2041 87.6752V106.99C34.2041 115.975 41.1685 123.258 49.7595 123.258C58.3506 123.258 65.315 115.975 65.315 106.99V87.6752Z"
      fill="#46877F"
      stroke="#0E0E10"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M93.5496 149.184C93.5496 149.184 91.9711 138.603 80.7587 137.069C69.5463 135.535 43.7028 135.517 47.5545 117.672C51.289 100.372 91.0149 110.468 100.838 112.223C111.897 114.198 123.912 119.1 129 127.511C134.087 135.923 135.314 147.032 135.314 147.032"
      fill="#46877F"
    />
    <path
      d="M93.5496 149.184C93.5496 149.184 91.9711 138.603 80.7587 137.069C69.5463 135.535 43.7028 135.517 47.5545 117.672C51.289 100.372 91.0149 110.468 100.838 112.223C111.897 114.198 123.912 119.1 129 127.511C134.087 135.923 135.314 147.032 135.314 147.032"
      stroke="#0E0E10"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
  </svg>
);
