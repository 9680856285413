import classNames from "classnames";
import PropTypes from "prop-types";

export const Play = (props) => (
  <div>
    <svg
      className={classNames({
        [props.className]: props.className,
      })}
      onClick={props.onClick}
      width="100%"
      height="100%"
      viewBox="0 0 670 670"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M335 667c183.269 0 331.838-148.641 331.838-332S518.269 3 335 3C151.731 3 3.162 151.641 3.162 335S151.731 667 335 667z"
        fill="#ECECE7"
        stroke="#0E0E10"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M510.826 361.027 269.502 500.429a30.027 30.027 0 0 1-41.055-10.991 30.068 30.068 0 0 1-4.024-15.035V195.597a30.052 30.052 0 0 1 15.025-26.04 30.038 30.038 0 0 1 15.029-4.024 30.039 30.039 0 0 1 15.025 4.037l241.324 139.368a30.04 30.04 0 0 1 11.015 11.003 30.072 30.072 0 0 1 0 30.082 30.05 30.05 0 0 1-11.015 11.004z"
        fill="#46877F"
        stroke="#0E0E10"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
    </svg>
  </div>
);

Play.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};