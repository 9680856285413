import { Smile } from "../../smilies/smile";
import { Neutral } from "../../smilies/neutral";
import { Evil } from "../../smilies/evil";
import { Arrow } from "../../components/arrow";

export const Page0 = () => {
  return (
    <div>
      <header className="flex items-baseline justify-center py-16 px-10 pb-10">
        <h3 className="mt-4 mb-8 mr-10 text-center font-Monument text-[100px] font-bold leading-tight text-freiburg-red">
          1
        </h3>
        <p className="mb-8 text-center text-6xl font-bold leading-tight">
          Wähle ein Wort aus
        </p>
      </header>

      <div className="mx-auto max-w-[53.5rem] border-2 border-freiburg-black px-20 pb-28">
        <div className="mt-6 mb-6 flex items-center justify-center">
          <Smile className="mx-2 max-w-[5.5rem]" />
          <Neutral className="mx-2 max-w-[5.5rem]" />
          <Evil className="mx-2 max-w-[5.5rem]" />
        </div>

        <div className="flex items-center justify-between">
          <Arrow fill="#ecece7" direction="left" className="max-w-[3rem]" />
          <div>
            <div className="rounded-3xl border-2 border-black px-36 py-28 text-center">
              <span className="font-Monument text-[2.5rem] font-extrabold">
                Begriff
              </span>
            </div>
          </div>
          <Arrow fill="#ecece7" className="max-w-[3rem]" />
        </div>
      </div>
    </div>
  );
};
