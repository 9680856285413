import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useState, useEffect } from "react";

import { Smile } from "../smilies/smile";
import { Button } from "../components/button";

export const Close = () => {
  const [showBubble, setShowBubble] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setShowBubble(true);
    }, 500);
  }, []);

  return (
    <div className="relative top-1/4  flex h-1/2 w-full items-center justify-center">
      <svg
        className={classNames({
          "z-10 -mr-40 -mt-[10rem] h-auto max-h-[45rem] w-1/2 max-w-6xl self-start": true,
          invisible: !showBubble,
        })}
        width="1114"
        height="711"
        viewBox="0 0 1114 711"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1107.55 321.78C1107.55 147.386 860.968 6 556.777 6C252.586 6 6 147.386 6 321.78C6 496.173 252.586 637.582 556.777 637.582C623.073 637.736 689.193 630.736 753.987 616.704C825.788 665.485 908.792 695.25 995.23 703.213C995.23 703.213 943.374 630.187 931.56 553.192C1039.8 495.519 1107.55 413.204 1107.55 321.78Z"
          fill="#ECECE7"
          stroke="#0E0E10"
          strokeWidth="12"
          strokeMiterlimit="10"
        />
        <foreignObject className="fixed block h-full w-full">
          <div className="fixed top-[370px] left-[50px] -mt-16 flex h-full flex-col items-center justify-center px-40">
            <div className="text-center text-7xl font-bold">
              Möchtest du das Spiel wirklich beenden?
            </div>
            <div className="mt-16 flex flex-row items-center justify-between space-x-8">
              <Button
                onClick={() => navigate("/")}
                className="min-w-[18rem] bg-freiburg-green px-10 py-6 pt-6 text-6xl text-[2.8rem]"
              >
                Ja
              </Button>
              <Button
                onClick={() => navigate(-1)}
                className="min-w-[18rem] bg-freiburg-red px-10 py-6 pt-6 text-6xl text-[2.8rem]"
              >
                Nein
              </Button>
            </div>
          </div>
        </foreignObject>
      </svg>
      <Smile
        className="-mb-20 h-auto w-full max-w-[39rem] self-end"
        fill="#DDAF27"
      />
    </div>
  );
};
