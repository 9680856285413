import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { Pager, PagerProvider } from "@crowdlinker/react-pager";

import { Header } from "./header";
import { setByValue as setCurrentQuestionByValue } from "./currentQuestionSlice";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Arrow } from "../components/arrow";

export const Overview = () => {
  const [activeIndex, onChange] = useState(0);
  const questions = useSelector((state) => state.question.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeQuestion, setActiveQuestions] = useState(
    questions.slice((activeIndex - 1) * 9, activeIndex * 9 + 1)
  );

  useEffect(() => {
    setActiveQuestions(
      questions.slice((activeIndex - 1) * 9, activeIndex * 9 + 1)
    );
  }, [activeIndex]);

  return (
    <div>
      <Header />
      <div className="flex items-center justify-center">
        <Arrow
          direction="left"
          onClick={() => onChange(activeIndex - 1 === -1 ? 3 : activeIndex - 1)}
          className="absolute left-20 top-[calc(50%-15px)] z-20 -mb-72 h-auto w-[9rem] cursor-pointer"
        />
        <PagerProvider activeIndex={activeIndex} onChange={onChange}>
          <Pager>
            <div className="mx-40 mt-52 grid w-full grid-cols-3 gap-12 px-36">
              {questions.slice(0, 9).map((item, index) => {
                return (
                  <div
                    key={index}
                    className={classNames({
                      "mb-4 flex h-64 cursor-pointer items-center justify-center rounded-3xl border-[6px] px-10 text-center font-Monument text-4xl font-extrabold": true,
                      "border-black bg-freiburg-white text-black": !item.used,
                      "border-none bg-black text-freiburg-white": item.used,
                    })}
                    onClick={() => {
                      dispatch(setCurrentQuestionByValue(index));
                      navigate("/spielen");
                    }}
                  >
                    {item.titel}
                  </div>
                );
              })}
            </div>
            <div className="mx-40 mt-52 grid w-full grid-cols-3 gap-12 px-36">
              {questions.slice(9, 18).map((item, index) => {
                return (
                  <div
                    key={index}
                    className={classNames({
                      "flex h-64 cursor-pointer items-center justify-center rounded-3xl border-[6px] px-10 text-center font-Monument text-4xl font-extrabold": true,
                      "border-black bg-freiburg-white text-black": !item.used,
                      "border-none bg-black text-freiburg-white": item.used,
                    })}
                    onClick={() => {
                      dispatch(setCurrentQuestionByValue(index + 9));
                      navigate("/spielen");
                    }}
                  >
                    {item.titel}
                  </div>
                );
              })}
            </div>
            <div className="mx-40 mt-52 grid w-full grid-cols-3 gap-12 px-36">
              {questions.slice(18, 27).map((item, index) => {
                return (
                  <div
                    key={index}
                    className={classNames({
                      "flex h-64 cursor-pointer items-center justify-center rounded-3xl border-[6px] px-10 text-center font-Monument text-4xl font-extrabold": true,
                      "border-black bg-freiburg-white text-black": !item.used,
                      "border-none bg-black text-freiburg-white": item.used,
                    })}
                    onClick={() => {
                      dispatch(setCurrentQuestionByValue(index + 18));
                      navigate("/spielen");
                    }}
                  >
                    {item.titel}
                  </div>
                );
              })}
            </div>
            <div className="mx-40 mt-52 grid w-full grid-cols-3 gap-12 px-36">
              {questions.slice(27, 36).map((item, index) => {
                return (
                  <div
                    key={index}
                    className={classNames({
                      "flex h-64 cursor-pointer items-center justify-center rounded-3xl border-[6px] px-10 text-center font-Monument text-4xl font-extrabold": true,
                      "border-black bg-freiburg-white text-black": !item.used,
                      "border-none bg-black text-freiburg-white": item.used,
                    })}
                    onClick={() => {
                      dispatch(setCurrentQuestionByValue(index + 27));
                      navigate("/spielen");
                    }}
                  >
                    {item.titel}
                  </div>
                );
              })}
            </div>
          </Pager>
        </PagerProvider>
        <Arrow
          onClick={() => onChange(activeIndex + 1 > 3 ? 0 : activeIndex + 1)}
          className="absolute top-[calc(50%-15px)] right-20 z-20 -mb-72 h-auto w-[9rem] cursor-pointer"
        />
      </div>
    </div>
  );
};
