import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Play } from "./components/play";
import content from "./../../content.json";
import { useDispatch } from "react-redux";
import { setByValue } from "../../game/questionSlice";

import StartVideo from "url: ../../../static/ampel-start.m4v";

export const Start = () => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [random, setRandom] = useState(Math.random());
  const navigate = useNavigate();
  const dispatch = useDispatch();

  dispatch(
    setByValue(content.begriffe.map((item) => ({ ...item, used: false })))
  );

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("state change");
      forceUpdate();
      setRandom(Math.random());
    }, 16000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="h-screen overflow-hidden">
      <div className="relative flex h-screen items-center justify-center overflow-hidden">
        <video
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline={true}
          className="absolute z-10 h-full w-full object-cover"
        >
          <source src={StartVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div
        onClick={() => navigate("/hilfe")}
        className="absolute top-1/2 left-1/2 z-50 -ml-4 max-w-[30%] -translate-x-1/2 -translate-y-1/2 transform cursor-pointer"
      >
        <Play className="h-auto w-[40rem] transition-all hover:scale-105" />
      </div>
    </div>
  );
};
