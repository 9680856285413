{
  "begriffe": [
    {
      "titel": "Eingeborene_r",
      "einstufung": "rassistisch",
      "erklaerung": "Das Wort wurde für Menschen aus unterworfenen Gesellschaften in den Kolonien verwendet, die häufig auch Opfer von Versklavung waren. Weiße werden nie so genannt! Die Bezeichnung sollte aus dem Wortschatz fliegen."
    },
    {
      "titel": "Schwarz",
      "einstufung": "ok",
      "erklaerung": "Bei dem Begriff „Schwarz“ geht es nicht um die Hautfarbe. Es ist eine politische Bezeichnung für Schwarze Menschen, die in einer weißen Gesellschaft rassistische und ausgrenzende Erfahrungen machen. Das Adjektiv wird daher immer groß geschrieben!"
    },
    {
      "titel": "Zivilisation",
      "einstufung": "kritisch",
      "erklaerung": "Der Begriff bezeichnet die gesellschaftliche Lebensform, geschaffen durch Fortschritt von Wissenschaft und Technik. Mit der Einbildung, zivilisierter als die lokale Bevölkerung zu sein, rechtfertigten Europäer_innen ihr rassistisches Verhalten gegenüber den Menschen in den Kolonien."
    },
    {
      "titel": "PoC",
      "einstufung": "ok",
      "erklaerung": "Person of Color (PoC) ist ein politischer Begriff und eine Selbstbezeichnung von Menschen mit ähnlichen Erfahrungen. Sie haben strukturelle Diskriminierung und Rassismus in Kulturen erlebt oder erleben dies immer noch, die von weißen Menschen und ihrer eurozentristischen Sichtweise dominiert werden."
    },
    {
      "titel": "Farbig/e",
      "einstufung": "rassistisch",
      "erklaerung": "Farbig/e ist eine koloniale Bezeichnung, die Menschen rassistisch einordnet. Es soll eine Abgrenzung zu „weiß“ und „schwarz“ als politische Begriffe herstellen und ist eine Erfindung der weißen „Rassen“-lehre."
    },
    {
      "titel": "Deutsch-Südwestafrika",
      "einstufung": "rassistisch",
      "erklaerung": "Mehr als 30 Jahre besetzte das deutsche Kaiserreich ein Gebiet im südlichen Afrika – das heutige Namibia. Die Gewalt gegen die Volksgruppen der Herero und Nama wird heute als Völkermord gewertet."
    },
    {
      "titel": "Indianer",
      "einstufung": "kritisch",
      "erklaerung": "„Indianer“ ist eine willkürliche Zusammenfassung zur Bezeichnung von geografischund kulturell diverser Gesellschaften in den Amerikas. Der Begriff diente den Kolonialist_innen zur Abgrenzung der eigenen Gruppe von der Gruppe derer, die sie überfallen haben."
    },
    {
      "titel": "Aborigines",
      "einstufung": "kritisch",
      "erklaerung": "Die weißen „Erober_innen“ bezeichneten mit diesem Wort die Australier_innen als „anders“. Damit rechtfertigten sie die Kolonisierung des australischen Gebiets, die mit Massakern, Vertreibungen, Zwangsumsiedlungen und anderen Arten von Unterdrückung einherging."
    },
    {
      "titel": "exotisch",
      "einstufung": "kritisch",
      "erklaerung": "„Exotisch“ bedeutet „fremdländisch, überseeisch“. . Die Menschen aus den Kolonien wurden als Objekte der „Exotik“ betrachtet und behandelt. Der Begriff kann als Bezeichnung für Menschen Anziehung, Abwertung, aber auch rassistische Sichtweisen ausdrücken."
    },
    {
      "titel": "Mischling",
      "einstufung": "rassistisch",
      "erklaerung": "Der Begriffgeht von der Idee aus, dass es verschiedene rassifizierte Gruppen gibt. Er bezieht sich auf Kinder mit Schwarzen und weißen Elternteilen. Im Nationalsozialismus wurden auch Menschen mit jüdischen und „deutschen“ Eltern so genannt und verfolgt."
    },
    {
      "titel": "Häuptling",
      "einstufung": "kritisch",
      "erklaerung": "Die Kolonisator_innen ignorierten die Selbstbezeichnungen der lokalen Bevölkerung komplett. Sie benannten deren diverse hochstehenden Persönlichkeiten pauschal als „Häuptling“ Eine weibliche Form gibt es nicht, die Existenz einer politisch mächtigen Frau war unvorstellbar."
    },
    {
      "titel": "Hottentotten",
      "einstufung": "rassistisch",
      "erklaerung": "Das niederländische Hottentott übernahmen deutsche Kolonisator_innen für Gesellschaften Afrikas, in deren Sprache Klick- und Schnalzlaute vorkommen. Der Begriff sollte diese Klicks nachahmen und die Sprecher_innen abwerten. Das Wort hat klare rassistisch kolonialistische Wurzeln."
    },
    {
      "titel": "indigen",
      "einstufung": "kritisch",
      "erklaerung": "Indigen ersetzt in der heutigen Sprache die kolonialistischen Bezeichnungen „eingeboren“ oder „Eingeborene_r“. Der Begriff ist daher problematisch und nicht neutral. Zudem wird er nur für außereuropäische Menschen genutzt und ruft unbewusst die Vorstellung von „Andersartigkeit“ und „Rückständigkeit“ hervor."
    },
    {
      "titel": "Verkafferung",
      "einstufung": "rassistisch",
      "erklaerung": "Der Begriff entstammt der deutschen Kolonialzeit in Südwestafrika. Er bezeichnet das unerwünschte Sich-Einlassen auf die Lebens- und Denkweise der lokalen Bevölkerung - diskriminierend „Kaffer“ genannt. Diese „Vermischung“ galt es zu vermeiden. Das Wort verweist eindeutig auf rassistische Ideologien."
    },
    {
      "titel": "Rasse",
      "einstufung": "rassistisch",
      "erklaerung": "Wir sind alle Homo sapiens sapiens – unabhängig von „Hautfarbe“, Aussehen oder Herkunft. Die Einteilung von Menschen besitzt keine wissenschaftliche Grundlage. Die Methode wurde als Rechtfertigung für koloniale und rassistische Machtausübung angewandt. Der Begriff steht noch im deutschen Grundgesetz und sollte gestrichen werden."
    },
    {
      "titel": "primitiv",
      "einstufung": "kritisch",
      "erklaerung": "Das lateinische Wort primitivus bedeutet der „erste in seiner Art“. Europäische Kolonialist_innen nutzten den Begriff im Sinne ihres Überlegenheitsdenkens, um fremde Kulturen abzuwerten und zu diskriminieren. Der Begriff sollte also mit Vorsicht verwendet werden."
    },
    {
      "titel": "Stamm",
      "einstufung": "kritisch",
      "erklaerung": "Weiße Kolonialist_innen bezeichneten alle gesellschaftlichen Organisationsformen auf dem afrikanischen Kontinent als „Stamm“. Sie verleugneten damit deren Vielfalt. Hier zeigt sich deutlich die Abwertung lokaler Gruppen als rückständig und unterlegen."
    },
    {
      "titel": "Entdecken",
      "einstufung": "ok",
      "erklaerung": "Entdecken und verstehen sind elementare Bausteine in der Entwicklung des Menschen. Im kolonialen Zusammenhang bedeutet „Entdecken“ jedoch, dass Weiße aus ihrer hierarchisch und interessensorientierten Perspektive erstmals etwas gesehen, erfahren oder betreten haben, was es schon lange gab bzw. gibt."
    },
    {
      "titel": "Neue Welt",
      "einstufung": "kritisch",
      "erklaerung": "Der Begriff zeigt die eurozentristische Weltsicht und entstand mit den europäischen „Entdeckungsreisen“ seit dem 15. Jahrhundert. Er wurde für die Gebiete des heutigen Nord- und Südamerikas, manchmal Australien und Neuseeland verwendet."
    },
    {
      "titel": "Kanake",
      "einstufung": "rassistisch",
      "erklaerung": "„Kanaka“ ist ein hawaiianisches Wort für Mensch. Weiße Kolonisator_innen benutzten es willkürlich für Bevölkerungsgruppen der westpazifischen Inseln, die sie ausbeuteten und unterdrückten. In Deutschland wurde der Ausdruck zu einem abwertenden Begriff für Einwander_innen mit südlichem Aussehen."
    },
    {
      "titel": "Kannibalismus",
      "einstufung": "rassistisch",
      "erklaerung": "Die Unterstellung der „Menschenfresserei“ diente Kolonisator_innen dazu, kolonisierte Gesellschaften zu entmenschlichen, um eigene brutale Handlungen zu rechtfertigen. Die kolonialistische Erfindung taucht auch in berühmten Kinder- und Jugendbüchern wie Robinson Crusoe oder Pippi Langstrumpf auf."
    },
    {
      "titel": "Naturreligion",
      "einstufung": "kritisch",
      "erklaerung": "Die Koloniator_innen setzten religiöse Vorstellungen und Handlungen der kolonisierten Gesellschaften in den Zusammenhang der Natur. Sie betonten damit den Unterschied zu ihrer westlichen monotheistischen, schriftlich verankerten Religion. Es gibt aber nicht die eine „richtige“ Glaubensrichtung."
    },
    {
      "titel": "Neger_in",
      "einstufung": "rassistisch",
      "erklaerung": "Aus der lateinischen Farbbezeichnung niger (schwarz) entstand in der Folge des kolonialen Machtstrebens ein rassifizierendes Konzept: Millionen Afrikaner_innen wurden versklavt und die Rassenlehre begründet. Die „Hautfarbe“ diente zur biologischen Einteilung"
    },
    {
      "titel": "Mohr_in",
      "einstufung": "rassistisch",
      "erklaerung": "„Der Begriff geht auf das griechische moros, töricht, gottlos, und auf das lateinische maurus, schwarz zurück. In der weißen Vorstellungswelt hatte das Wort eine rassistische Bedeutung und hob die Unterteilung zwischen weiß, also christlich, kultiviert und dunkel, also gottlos, ungebildet hervor."
    },
    {
      "titel": "wild",
      "einstufung": "rassistisch",
      "erklaerung": "Das Gegensatzpaar „wild“ und „zivilisiert“ spielte für die europäischen Kolonisator_innen eine große Rolle: Ihre brutalen Maßnahmen zur „Zähmung“ der lokalen Bevölkerungen rechtfertigten sie damit, dass deren „wilde“ Lebensformen und Charaktereigenschaften durch die weiße Kultur zivilisiert werden müsse."
    },
    {
      "titel": "Buschmann",
      "einstufung": "rassistisch",
      "erklaerung": "Die Bezeichnung zeigt eindeutig die rassistische Einstellung der Kolonisator_innen gegenüber der Diversität von Land und Mensch. Busch galt als Sinnbild für „wilden“ Lebensraum. Für Frauen gab es solche Bezeichnungen nicht."
    },
    {
      "titel": "Afrodeutsch",
      "einstufung": "ok",
      "erklaerung": "Den Begriff führten Schwarze Frauen ein. Er drückt Widerstand aus, aber auch Verbundenheit Schwarzer Erfahrungen mit allen Regionen der Welt. Viele Schwarze Menschen nutzen den Begriff, um ihre Zugehörigkeit zur deutschen Nation sowie ihre Erlebnisse als Schwarze Menschen sichtbar zu machen."
    },
    {
      "titel": "Dunkelhäutig",
      "einstufung": "kritisch",
      "erklaerung": "Der Begriff ist von weißen Menschen konstruiert. Wer ist denn dunkelhäutig im Vergleich zu wem? Der Begriff reduziert Menschen auf den „dunkleren“ Hautton. Daher sollte er nicht verwendet werden."
    },
    {
      "titel": "Mulatt_in",
      "einstufung": "rassistisch",
      "erklaerung": "Der Begriff bezeichnet meist Menschen mit einem weißen und einem Schwarzen Elternteil. Das Wort stammt aus dem spanisch-portugisieschem „mulato“ und heißt Maulesel. Der Begriff schafft daher eine Nähe zwischen Schwarzen Menschen und dem Tierreich. Er sollte nicht verwendet werden."
    }
  ]
}