import PropTypes from "prop-types";

export const ThumbsDown = (props) => {
  return (
    <svg
      onClick={props.onClick}
      className={props.className ?? props.className}
      width="843"
      height="931"
      viewBox="0 0 843 931"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M189.516 643.039L211.603 6.21149L349.782 6.21151C430.123 6.09994 507.444 36.7166 565.798 91.7471L778.854 91.7471C778.854 91.7471 838.225 148.58 836.281 290.596C834.381 433.36 766.307 494.947 766.307 494.947L608.294 498.337C598.547 512.074 588.093 525.298 576.974 537.957C563.721 552.749 506.294 612.047 505.587 666.986C505.057 723.159 537.04 907.569 484.03 920.467C364.758 949.434 357.38 811.38 353.979 720.166C353.475 704.486 348.039 689.36 338.44 676.926C328.84 664.492 315.563 655.379 300.483 650.875C279.739 645.295 258.31 642.657 236.827 643.039C228.831 643.039 224.326 642.378 222.779 641.101L189.516 643.039Z"
        fill="#D5654D"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M610.414 496.047C645.82 444.109 664.708 382.747 664.617 319.958L610.414 496.047Z"
        fill="#D5654D"
      />
      <path
        d="M610.414 496.047C645.82 444.109 664.708 382.747 664.617 319.958"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M36.8467 85.805L36.8467 85.849C36.8467 129.807 72.6047 165.441 116.715 165.441L211.603 165.441C255.713 165.441 291.471 129.807 291.471 85.849L291.471 85.805C291.471 41.8474 255.713 6.21283 211.603 6.21283L116.715 6.21282C72.6048 6.21282 36.8467 41.8473 36.8467 85.805Z"
        fill="#D5654D"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M6.18922 245.032L6.18922 245.076C6.18921 289.034 41.9473 324.668 86.0573 324.668L240.095 324.668C284.205 324.668 319.964 289.034 319.964 245.076L319.964 245.032C319.964 201.074 284.205 165.44 240.095 165.44L86.0573 165.44C41.9473 165.44 6.18922 201.074 6.18922 245.032Z"
        fill="#D5654D"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M6.18922 404.218L6.18922 404.262C6.18921 448.219 41.9473 483.855 86.0573 483.855L262.845 483.855C306.955 483.855 342.714 448.22 342.714 404.262L342.714 404.218C342.714 360.26 306.955 324.626 262.845 324.626L86.0573 324.626C41.9473 324.626 6.18922 360.26 6.18922 404.218Z"
        fill="#D5654D"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M6.14551 563.447L6.14551 563.491C6.14551 607.449 41.9035 643.084 86.0136 643.084L293.194 643.084C337.304 643.084 373.062 607.449 373.062 563.491L373.062 563.447C373.062 519.49 337.304 483.855 293.194 483.855L86.0136 483.855C41.9036 483.855 6.14552 519.49 6.14551 563.447Z"
        fill="#D5654D"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

ThumbsDown.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
