import PropTypes from "prop-types";

export const OpenMouth = (props) => {
  return (
    <svg
      onClick={props.onClick}
      className={props.className ?? props.className}
      width="100%"
      height="100%"
      viewBox="0 0 676 675"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M338.047 669C521.04 669 669.385 520.582 669.385 337.5C669.385 154.418 521.04 6 338.047 6C155.054 6 6.7085 154.418 6.7085 337.5C6.7085 520.582 155.054 669 338.047 669Z"
        fill="#DDAF27"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M338.054 530.516C391.329 530.516 434.516 487.308 434.516 434.007C434.516 380.706 391.329 337.498 338.054 337.498C284.779 337.498 241.592 380.706 241.592 434.007C241.592 487.308 284.779 530.516 338.054 530.516Z"
        fill="#0E0E10"
      />
      <path
        d="M247.743 263.953C265.173 263.953 279.304 249.815 279.304 232.376C279.304 214.937 265.173 200.8 247.743 200.8C230.312 200.8 216.182 214.937 216.182 232.376C216.182 249.815 230.312 263.953 247.743 263.953Z"
        fill="#0E0E10"
      />
      <path
        d="M428.347 263.953C445.777 263.953 459.908 249.815 459.908 232.376C459.908 214.937 445.777 200.8 428.347 200.8C410.916 200.8 396.786 214.937 396.786 232.376C396.786 249.815 410.916 263.953 428.347 263.953Z"
        fill="#0E0E10"
      />
    </svg>
  );
};

OpenMouth.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
