import { Smile } from "../../smilies/smile";
import { Neutral } from "../../smilies/neutral";
import { Evil } from "../../smilies/evil";

export const Page00 = () => {
  return (
    <div className="mx-auto flex h-full max-w-[90rem] flex-col items-center justify-center pr-32">
      <ul className="mx-auto">
        <li className="mb-16 flex items-start">
          <Smile className="h-auto w-full max-w-[8rem]" />
          <span className="line ml-14 text-[72px] font-bold leading-tight">
            o.k.: Der Begriff ist nicht durch eine weiße Überlegenheitshaltung
            geprägt.
          </span>
        </li>
        <li className="mb-16 flex items-start">
          <Neutral className="h-auto w-full max-w-[8rem]" />
          <span className="line ml-14 text-[72px] font-bold leading-tight">
            kritisch: Beim Gebrauch kommt es auf deine Haltung und den
            Zusammenhang an.
          </span>
        </li>
        <li className="mb-16 flex items-start">
          <Evil className="h-auto w-full max-w-[8rem]" />
          <span className="line ml-14 text-[72px] font-bold leading-tight">
            rassistisch: Der Begriff ist abwertend, ausgrenzend, verletzend.
          </span>
        </li>
      </ul>
    </div>
  );
};
