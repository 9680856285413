import PropTypes from "prop-types";

export const ThumbsUp = (props) => {
  return (
    <svg
      onClick={props.onClick}
      className={props.className ?? props.className}
      width="843"
      height="931"
      viewBox="0 0 843 931"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M652.925 287.56L630.838 924.387H492.662C412.323 924.499 335.005 893.882 276.652 838.852H63.6016C63.6016 838.852 4.23196 782.019 6.17561 640.003C8.07508 497.239 76.1473 435.651 76.1473 435.651L234.157 432.262C243.903 418.525 254.358 405.301 265.476 392.642C278.728 377.85 336.154 318.552 336.861 263.612C337.391 207.44 305.409 23.0301 358.418 10.1315C477.687 -18.8351 485.064 119.219 488.466 210.433C488.969 226.113 494.405 241.239 504.004 253.673C513.603 266.107 526.881 275.22 541.96 279.724C562.703 285.304 584.132 287.942 605.615 287.56C613.61 287.56 618.115 288.221 619.662 289.498L652.925 287.56Z"
        fill="#46877F"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M232.037 434.552C196.632 486.49 177.744 547.851 177.835 610.641Z"
        fill="#46877F"
      />
      <path
        d="M232.037 434.552C196.632 486.49 177.744 547.851 177.835 610.641"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M805.59 844.794V844.75C805.59 800.792 769.833 765.158 725.724 765.158H630.838C586.729 765.158 550.972 800.792 550.972 844.75V844.794C550.972 888.751 586.729 924.386 630.838 924.386H725.724C769.833 924.386 805.59 888.751 805.59 844.794Z"
        fill="#46877F"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M836.246 685.567V685.523C836.246 641.565 800.489 605.931 756.38 605.931H602.346C558.237 605.931 522.479 641.565 522.479 685.523V685.567C522.479 729.525 558.237 765.159 602.346 765.159H756.38C800.489 765.159 836.246 729.525 836.246 685.567Z"
        fill="#46877F"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M836.246 526.381V526.337C836.246 482.379 800.489 446.744 756.38 446.744L579.596 446.744C535.487 446.744 499.73 482.379 499.73 526.337V526.381C499.73 570.339 535.487 605.973 579.596 605.973H756.38C800.489 605.973 836.246 570.339 836.246 526.381Z"
        fill="#46877F"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M836.29 367.151V367.107C836.29 323.15 800.533 287.515 756.424 287.515L549.249 287.515C505.14 287.515 469.383 323.15 469.383 367.107V367.151C469.383 411.109 505.14 446.744 549.249 446.744L756.424 446.744C800.533 446.744 836.29 411.109 836.29 367.151Z"
        fill="#46877F"
        stroke="#0E0E10"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

ThumbsUp.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
