import { Smile } from "../../smilies/smile";
import { Neutral } from "../../smilies/neutral";
import { Evil } from "../../smilies/evil";

export const Page0 = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center ">
      <p className="-mt-20 mb-28 text-center text-[72px] font-bold leading-tight">
        Entscheide: Ist das Wort für dich
      </p>
      <ul className="mx-auto w-max">
        <li className="mb-6 flex items-center">
          <Smile className="h-auto max-w-[8rem]" />
          <span className="line ml-14 text-[72px] font-bold leading-tight">
            o.k.?
          </span>
        </li>
        <li className="mb-6 flex items-center">
          <Neutral className="h-auto max-w-[8rem]" />
          <span className="line ml-14 text-[72px] font-bold leading-tight">
            kritisch?
          </span>
        </li>
        <li className="mb-6 flex items-center">
          <Evil className="h-auto max-w-[8rem]" />
          <span className="line ml-14 text-[72px] font-bold leading-tight">
            rassistisch?
          </span>
        </li>
      </ul>
    </div>
  );
};
