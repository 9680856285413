import PropTypes from "prop-types";

export const Button = (props) => {
  return (
    <button
      className={`mx-auto block rounded-3xl border-[6px] border-black p-8 px-16 pt-10 text-center font-Monument text-7xl font-extrabold ${props.className}`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.element,
};
