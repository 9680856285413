import { configureStore } from "@reduxjs/toolkit";

import questionReducer from "./game/questionSlice";
import currentQuestionReducer from "./game/currentQuestionSlice";

export default configureStore({
  reducer: {
    question: questionReducer,
    currentQuestion: currentQuestionReducer,
  },
});
