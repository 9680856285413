import React from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useIdleTimer } from "react-idle-timer";

import { Start } from "./pages/start/start";
import { Help } from "./pages/help/help";
import { Game } from "./game/game";
import { Overview } from "./game/overview";
import { InGameHelp } from "./pages/in-game-help/in-game-help";
import { Close } from "./pages/close";
import { Fazit } from "./game/fazit";
import { CloseIdle } from "./pages/close-idle";
import { FazitFinal } from "./game/fazit-final";

export const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const colorClass = classNames({
    "bg-freiburg-red": location.pathname === "/",
    "bg-freiburg-yellow":
      location.pathname.includes("/spielen") &&
      !location.pathname.includes("/hilfe") &&
      !location.pathname.includes("/fazit") &&
      !location.pathname.includes("/idle"),
    "bg-freiburg-green":
      location.pathname.includes("/hilfe") ||
      location.pathname.includes("/fazit"),
    "bg-freiburg-black":
      location.pathname.includes("/exit") ||
      location.pathname.includes("/idle"),
  });

  const onIdle = () => {
    navigate("/spielen/idle");
  };

  const idleTimer = useIdleTimer({ onIdle, timeout: 1000 * 1 * 60 });

  return (
    <div
      className={`${colorClass} relative h-screen w-screen overflow-x-hidden`}
    >
      <Routes>
        <Route path="/" element={<Start />} />
        <Route path="/hilfe" element={<Help />} />
        <Route path="/spielen/hilfe" element={<InGameHelp />} />
        <Route path="/spielen" element={<Game />} />
        <Route path="/spielen/uebersicht" element={<Overview />} />
        <Route path="/spielen/fazit" element={<Fazit />} />
        <Route path="/spielen/fazit/final" element={<FazitFinal />} />
        <Route path="/spielen/idle" element={<CloseIdle />} />
        <Route path="/exit" element={<Close />} />
      </Routes>
    </div>
  );
};
