import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import classNames from "classnames";

import { Smile } from "../smilies/smile";
import { Button } from "../components/button";
import { CloseIcon } from "../components/close-icon";
import { OpenMouth } from "../smilies/open-mouth";

export const CloseIdle = () => {
  const navigate = useNavigate();
  const [showBubble, setShowBubble] = useState(false);
  const [timer, setTimer] = useState(11);

  useEffect(() => {
    setTimeout(() => {
      setShowBubble(true);
    }, 1000);
  }, []);

  useEffect(() => {
    const timerId = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        navigate("/");
      }
    }, 1000);

    return () => clearInterval(timerId);
  });

  return (
    <>
      <nav className="fixed flex w-full justify-end px-14">
        <CloseIcon
          variant="light"
          className="mt-10 max-w-[7rem] cursor-pointer"
          onClick={() => navigate(-1)}
        />
      </nav>
      <div className="relative top-1/4 flex h-1/2 w-full items-center justify-center">
        <svg
          className={classNames({
            "z-10 -mr-[9rem] -mt-[8rem] h-auto max-h-[45rem] w-1/2 max-w-6xl self-start": true,
            invisible: !showBubble,
          })}
          width="1114"
          height="711"
          viewBox="0 0 1114 711"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1107.55 321.78C1107.55 147.386 860.968 6 556.777 6C252.586 6 6 147.386 6 321.78C6 496.173 252.586 637.582 556.777 637.582C623.073 637.736 689.193 630.736 753.987 616.704C825.788 665.485 908.792 695.25 995.23 703.213C995.23 703.213 943.374 630.187 931.56 553.192C1039.8 495.519 1107.55 413.204 1107.55 321.78Z"
            fill="#ECECE7"
            stroke="#0E0E10"
            strokeWidth="12"
            strokeMiterlimit="10"
          />
          <foreignObject className="block h-full w-full">
            <div className="fixed top-[370px] left-[50px] -mt-10 flex h-full w-[1000px] flex-col items-center justify-center px-40">
              <div className="text-center text-7xl font-bold leading-tight">
                Das Spiel endet in
              </div>
              <div className="flex flex-row items-center justify-center">
                <div className="text-center font-Monument text-[14rem] font-bold leading-tight">
                  {timer}
                </div>
              </div>
            </div>
          </foreignObject>
        </svg>
        <OpenMouth className="-mb-20 h-auto w-full max-w-xl self-end" />
      </div>
    </>
  );
};
