import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Arrow } from "../../components/arrow";
import { Page0 } from "./page-0";
import { Page1 } from "./page-1";
import { Page2 } from "./page-2";
import { Page3 } from "./page-3";
import { Page4 } from "./page-4";
import { ThumbsUp } from "../../components/thumbs-up";
import { Button } from "../../components/button";

export const InGameHelp = () => {
  const [page, setPage] = useState(0);
  const maxPage = 4;

  let navigate = useNavigate();

  return (
    <>
      <header className="mt-14">
        <h1 className="text-center font-Monument text-[115px] font-extrabold leading-tight">
          Rassistisch oder nicht?
        </h1>
        <h2 className="text-center text-[84px] font-bold leading-tight">
          Teste doch mal deine Alltagssprache.
        </h2>
      </header>

      <main className="mx-14 mt-12 h-[70%] rounded-[45px] border-[6px] border-black bg-freiburg-white">
        <div className="relative h-full">
          {page !== 0 ? (
            <Arrow
              direction="left"
              className="absolute left-16 top-1/2 h-auto max-w-[6rem] -translate-y-1/2 cursor-pointer"
              onClick={() => setPage(page - 1)}
            />
          ) : null}

          {(() => {
            switch (page) {
              case 0:
                return <Page0 />;
              case 1:
                return <Page1 />;
              case 2:
                return <Page2 />;
              case 3:
                return <Page3 />;
              case 4:
                return <Page4 />;
            }
          })()}

          {page !== maxPage ? (
            <Arrow
              direction="right"
              className="absolute right-16 top-1/2 h-auto max-w-[6rem] -translate-y-1/2 cursor-pointer"
              onClick={() => setPage(page + 1)}
            />
          ) : null}
        </div>
        <Button
          className="relative -mt-20 bg-freiburg-yellow font-Monument"
          onClick={() => navigate("/spielen")}
        >
          Los geht's
          <ThumbsUp className="absolute -right-[5rem] -top-[5rem] h-auto max-w-[7.5rem]" />
        </Button>
      </main>
    </>
  );
};
