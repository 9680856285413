import PropTypes from "prop-types";

export const SmileyNeutral = (props) => {
  return (
    <svg
      onClick={props.onClick}
      className={props.className ?? props.className}
      width="238"
      height="238"
      viewBox="0 0 238 238"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M118.943 235C182.977 235 234.887 183.065 234.887 119C234.887 54.935 182.977 3 118.943 3C54.9096 3 3 54.935 3 119C3 183.065 54.9096 235 118.943 235Z"
        fill="#DDAF27"
        stroke="#0E0E10"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M186.724 119H51.1582"
        stroke="#0E0E10"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M98.3856 93.6348C98.3856 90.7043 97.2221 87.8938 95.1509 85.8217C93.0798 83.7495 90.2705 82.5854 87.3414 82.5854C84.4124 82.5854 81.6034 83.7495 79.5322 85.8217C77.4611 87.8938 76.2976 90.7043 76.2976 93.6348"
        stroke="#0E0E10"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M161.579 93.6348C161.629 92.1534 161.38 90.6771 160.847 89.2938C160.315 87.9106 159.51 86.6485 158.48 85.583C157.45 84.5174 156.217 83.6701 154.853 83.0914C153.489 82.5127 152.023 82.2144 150.541 82.2144C149.06 82.2144 147.593 82.5127 146.229 83.0914C144.865 83.6701 143.632 84.5174 142.602 85.583C141.572 86.6485 140.767 87.9106 140.234 89.2938C139.702 90.6771 139.454 92.1534 139.503 93.6348"
        stroke="#0E0E10"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

SmileyNeutral.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
