import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Smile } from "../smilies/smile";
import { Neutral } from "../smilies/neutral";
import { Evil } from "../smilies/evil";
import { ThumbsUp } from "./reactions/thumbs-up";
import { ThumbsDown } from "./reactions/thumbs-down";
import classNames from "classnames";
import { Button } from "../components/button";
import { SmileyEvil } from "./reactions/smiley-evil";
import { SmileyNeutral } from "./reactions/smiley-neutral";
import { SmileyOk } from "./reactions/smiley-ok";

import BubbleCorrectEvil from "../../static/bubble-smiley-correct-evil.svg";
import BubbleCorrectOk from "../../static/bubble-smiley-correct-ok.svg";
import BubbleCorrectKritisch from "../../static/bubble-smiley-correct-kitisch.svg";
import BubbleWrongEvil from "../../static/bubble-smiley-wrong-evil.svg";
import BubbleWrongOk from "../../static/bubble-smiley-wrong-ok.svg";
import BubbleWrongKritisch from "../../static/bubble-smiley-wrong-kitisch.svg";

export const Question = (props) => {
  const [userRating, setUserRating] = useState(null);
  const [userRatingReaction, setUserRatingReaction] = useState(null);

  // Enter reaction mode.
  useEffect(() => {
    if (userRating) {
      setTimeout(() => {
        props.setMode("reaction");

        if (userRating === props.q.einstufung) {
          setUserRatingReaction("thumbs up");
        } else {
          setUserRatingReaction("thumbs down");
        }
      }, 25);
    }
  }, [userRating]);

  // Enter description mode.
  useEffect(() => {
    if (userRatingReaction) {
      setTimeout(() => {
        props.setMode("description");
      }, 1250);
    }
  }, [userRatingReaction]);

  // Reset rating on question change.
  useEffect(() => {
    setUserRatingReaction(null);
    setUserRating(null);
  }, [props.q]);

  return (
    <>
      {!userRatingReaction ? (
        <div className="flex flex-col items-center justify-center">
          <div className="my-8 mb-16 flex items-center justify-center">
            {!userRating || userRating === "ok" ? (
              <Smile
                className="mx-12 h-auto w-[14rem] cursor-pointer"
                onClick={() => setUserRating("ok")}
              />
            ) : null}

            {!userRating || userRating === "kritisch" ? (
              <Neutral
                onClick={() => setUserRating("kritisch")}
                className="mx-12 h-auto w-[14rem] cursor-pointer"
              />
            ) : null}

            {!userRating || userRating === "rassistisch" ? (
              <Evil
                onClick={() => setUserRating("rassistisch")}
                className="mx-12 h-auto w-[14rem] cursor-pointer"
              />
            ) : null}
          </div>
          <div
            className={classNames({
              "flex aspect-[16/9.5] w-[73rem] items-center justify-center rounded-[55px] border-[6px] px-36 py-8 text-center": true,
              "border-black bg-freiburg-white text-black": !props.q.used,
              "border-none bg-black text-freiburg-white": props.q.used,
            })}
          >
            <span className="select-none font-Monument text-8xl font-extrabold">
              {props.q.titel}
            </span>
          </div>
        </div>
      ) : null}

      {props.mode === "description" ? (
        <div className="flex flex-col items-center justify-center">
          <div className="relative mt-8 mb-8 flex items-center justify-center">
            {props.q.einstufung === "ok" ? (
              <>
                <SmileyOk className="mx-6 h-auto w-[14rem] cursor-pointer" />
                {userRating === "ok" ? (
                  <img
                    src={BubbleCorrectOk}
                    className="absolute left-[calc(50%+47px)] -top-[200px] h-auto w-[30rem] max-w-none"
                  />
                ) : (
                  <img
                    src={BubbleWrongOk}
                    className="absolute left-[calc(50%+47px)] -top-[200px] h-auto w-[30rem] max-w-none"
                  />
                )}
              </>
            ) : null}
            {props.q.einstufung === "kritisch" ? (
              <>
                <SmileyNeutral className="mx-6 h-auto w-[14rem] cursor-pointer" />
                {userRating === "kritisch" ? (
                  <img
                    src={BubbleCorrectKritisch}
                    className="absolute left-[calc(50%+47px)] -top-[200px] h-auto w-[30rem] max-w-none"
                  />
                ) : (
                  <img
                    src={BubbleWrongKritisch}
                    className="absolute left-[calc(50%+47px)] -top-[200px] h-auto w-[30rem] max-w-none"
                  />
                )}
              </>
            ) : null}
            {props.q.einstufung === "rassistisch" ? (
              <>
                <SmileyEvil className="mx-6 h-auto w-[14rem] cursor-pointer" />
                {userRating === "rassistisch" ? (
                  <img
                    src={BubbleCorrectEvil}
                    className="absolute left-[calc(50%+47px)] -top-[200px] h-auto w-[30rem] max-w-none"
                  />
                ) : (
                  <img
                    src={BubbleWrongEvil}
                    className="absolute left-[calc(50%+47px)] -top-[200px] h-auto w-[30rem] max-w-none"
                  />
                )}
              </>
            ) : null}
          </div>
          <div className="flex aspect-video w-[73rem] flex-col items-center justify-center rounded-[55px] border-[6px] border-black bg-freiburg-white px-[5.8rem] py-12 text-center text-black">
            <span className="mb-[2.5rem] select-none font-Monument text-6xl font-extrabold">
              {props.q.titel}
            </span>
            <p className="text-left text-[2.75rem] font-bold leading-[3.2rem]">
              {props.q.erklaerung}
            </p>
          </div>
          <div className="mt-[73px] flex flex-row space-x-16">
            <Button
              className="min-w-[30rem] bg-freiburg-white text-5xl"
              onClick={() => {
                setUserRatingReaction(null);
                setUserRating(null);
                props.setMode(null);
              }}
            >
              nochmal
            </Button>
            <Button
              className="min-w-[30rem] bg-freiburg-white text-5xl"
              onClick={() => props.next("+")}
            >
              weiter
            </Button>
          </div>
        </div>
      ) : null}

      {userRatingReaction &&
      props.mode === "reaction" &&
      userRatingReaction === "thumbs up" ? (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <ThumbsUp className="h-auto max-w-[60rem] transform animate-[bounceIn_1s_ease]" />
        </div>
      ) : null}

      {userRatingReaction &&
      props.mode === "reaction" &&
      userRatingReaction === "thumbs down" ? (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <ThumbsDown className="h-auto max-w-[60rem] transform animate-[bounceIn_1s_ease]" />
        </div>
      ) : null}
    </>
  );
};

Question.propTypes = {
  q: PropTypes.shape({
    titel: PropTypes.string.isRequired,
    einstufung: PropTypes.string.isRequired,
    erklaerung: PropTypes.string.isRequired,
    used: PropTypes.bool.isRequired,
  }).isRequired,
  setMode: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(["description", "reaction"]),
  next: PropTypes.func.isRequired,
};
