import classNames from "classnames";
import PropTypes from "prop-types";

export const CloseIcon = (props) => {
  return (
    <svg
      className={classNames({
        [props.className]: props.className,
      })}
      onClick={props.onClick}
      width="132"
      height="132"
      viewBox="0 0 132 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M65.8946 128.937C100.583 128.937 128.703 100.817 128.703 66.1287C128.703 31.4407 100.583 3.32056 65.8946 3.32056C31.2066 3.32056 3.08643 31.4407 3.08643 66.1287C3.08643 100.817 31.2066 128.937 65.8946 128.937Z"
        fill={props.variant === "light" ? "#ECECE7" : "#0E0E10"}
        stroke={props.variant === "light" ? "#ECECE7" : "#0E0E10"}
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M34.7117 96.6149L97.962 33.3645"
        stroke={props.variant === "light" ? "#0E0E10" : "#ECECE7"}
        strokeWidth="16"
        strokeMiterlimit="10"
      />
      <path
        d="M97.962 96.6149L34.7117 33.3645"
        stroke={props.variant === "light" ? "#0E0E10" : "#ECECE7"}
        strokeWidth="16"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

CloseIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string,
};
