import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import FazitVideo from "url: ../../../static/AMPEL_FAZIT_FINAL.m4v";

export const FazitFinal = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 1000 * 18);
  }, []);

  return (
    <div className="h-screen">
      <div className="relative flex h-screen items-center justify-center overflow-hidden">
        <video
          autoPlay
          muted
          className="absolute z-10 h-full w-full object-cover"
        >
          <source src={FazitVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};
