import classNames from "classnames";
import PropTypes from "prop-types";

export const Info = (props) => {
  return (
    <svg
      className={classNames({
        [props.className]: props.className,
      })}
      onClick={props.onClick}
      width="135"
      height="135"
      viewBox="0 0 135 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.3603 131.403C102.729 131.403 131.401 102.73 131.401 67.3615C131.401 31.9927 102.729 3.32056 67.3603 3.32056C31.9915 3.32056 3.31934 31.9927 3.31934 67.3615C3.31934 102.73 31.9915 131.403 67.3603 131.403Z"
        fill="#0E0E10"
        stroke="#0E0E10"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M76.4312 54.764H58.2959V100.099H76.4312V54.764Z"
        fill="#ECECE7"
      />
      <path
        d="M67.4543 25.2614L57.0913 35.6244L67.4543 45.9873L77.8172 35.6244L67.4543 25.2614Z"
        fill="#ECECE7"
      />
    </svg>
  );
};

Info.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
