import PropTypes from "prop-types";

import classNames from "classnames";

export const Arrow = (props) => (
  <svg
    className={classNames({
      [props.className]: props.className,
      "rotate-180": props.direction === "left",
    })}
    onClick={props.onClick}
    width="105"
    height="111"
    viewBox="0 0 105 111"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5581 63.6231H73.9657L44.5765 93.2643C43.7814 94.0658 43.1506 95.0175 42.7203 96.065C42.29 97.1125 42.0684 98.2353 42.0684 99.3691C42.0684 100.503 42.29 101.626 42.7203 102.673C43.1506 103.721 43.7814 104.672 44.5765 105.474C46.1814 107.092 48.358 108.001 50.6274 108.001C52.8969 108.001 55.0735 107.092 56.6784 105.474L100.515 61.0807C102.107 59.4638 103 57.2779 103 54.9995C103 52.7211 102.107 50.5351 100.515 48.9182L56.6784 4.52906C55.0735 2.91068 52.8969 2.00146 50.6274 2.00146C48.358 2.00146 46.1814 2.91068 44.5765 4.52906C42.9719 6.14773 42.0704 8.34292 42.0704 10.6319C42.0704 12.9208 42.9719 15.1161 44.5765 16.7347L73.9657 46.3798H10.5581C8.28834 46.3798 6.11163 47.2892 4.50668 48.9079C2.90173 50.5266 2 52.7221 2 55.0113C2.00515 57.2971 2.90913 59.4875 4.51352 61.1019C6.11791 62.7164 8.2917 63.6231 10.5581 63.6231Z"
      fill={props.fill ? props.fill : "#46877F"}
      stroke="#0E0E10"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
  </svg>
);

Arrow.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(["left", "right"]),
  onClick: PropTypes.func,
  fill: PropTypes.string,
};
