import { createSlice } from "@reduxjs/toolkit";

import content from "./../content.json";

export const questionSlice = createSlice({
  name: "question",
  initialState: {
    value: content.begriffe.map((item) => ({ ...item, used: false })),
  },
  reducers: {
    setByValue: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setByValue } = questionSlice.actions;

export default questionSlice.reducer;
