import { Arrow } from "../../components/arrow";
import { Button } from "../../components/button";
import { SmileyNeutral } from "../../game/reactions/smiley-neutral";
import StartImage from "./../../../static/bubble-smiley-wrong-kitisch.svg";

export const Page3 = () => {
  return (
    <div>
      <header className="flex items-baseline justify-center py-16 px-10 pb-10">
        <h3 className="mt-4 mb-8 mr-10 text-center font-Monument text-[100px] font-bold leading-tight text-freiburg-red">
          4
        </h3>
        <p className="mb-8 text-center text-6xl font-bold leading-tight">
          Der Smiley sagt dir die richtige Antwort.
        </p>
      </header>

      <div className="mx-auto max-w-[53.5rem] border-2 border-freiburg-black px-20 pb-10 pt-4">
        <div className="relative mt-20 mb-6 flex items-center justify-center">
          <img
            src={StartImage}
            className="absolute left-[calc(50%+15px)] -top-[80px] max-w-[18rem]"
          />
          <SmileyNeutral className="mx-2 h-auto max-w-[5.5rem]" />
        </div>

        <div className="flex items-center justify-between">
          <Arrow
            fill="#ecece7"
            direction="left"
            className="-mt-[4.5rem] max-w-[3rem]"
          />
          <div>
            <div className="rounded-2xl border-2 border-black px-24 py-16 pt-8 text-center">
              <span className="mb-6 block font-Monument text-[1.5rem] font-extrabold">
                Begriff
              </span>
              <span className="mb-8 block text-[1rem]">
                Hier steht eine Info zum Begriff
              </span>
            </div>
            <div className="mt-6 flex px-2">
              <Button className="min-w-[11rem] rounded-2xl border-2 bg-freiburg-white p-2 px-6 pt-2 font-Monument text-xl">
                nochmal
              </Button>
              <Button className="min-w-[11rem] rounded-2xl border-2 bg-freiburg-white p-2 px-6 pt-2 font-Monument text-xl">
                weiter
              </Button>
            </div>
          </div>
          <Arrow fill="#ecece7" className="-mt-[4.5rem] max-w-[3rem]" />
        </div>
      </div>
    </div>
  );
};
