import PropTypes from "prop-types";

export const Neutral = (props) => (
  <svg
    onClick={props.onClick}
    className={props.className ?? props.className}
    width="135"
    height="135"
    viewBox="0 0 135 135"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M67.611 133.172C103.847 133.172 133.222 103.797 133.222 67.5606C133.222 31.3247 103.847 1.94965 67.611 1.94965C31.375 1.94965 2 31.3247 2 67.5606C2 103.797 31.375 133.172 67.611 133.172Z"
      fill="#DDAF27"
      stroke="#0E0E10"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M105.968 67.5605H29.2524"
      stroke="#0E0E10"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M49.7277 53.004C53.1793 53.004 55.9774 50.206 55.9774 46.7544C55.9774 43.3028 53.1793 40.5047 49.7277 40.5047C46.2761 40.5047 43.478 43.3028 43.478 46.7544C43.478 50.206 46.2761 53.004 49.7277 53.004Z"
      fill="#0E0E10"
    />
    <path
      d="M85.4943 53.004C88.9459 53.004 91.744 50.206 91.744 46.7544C91.744 43.3028 88.9459 40.5047 85.4943 40.5047C82.0427 40.5047 79.2446 43.3028 79.2446 46.7544C79.2446 50.206 82.0427 53.004 85.4943 53.004Z"
      fill="#0E0E10"
    />
  </svg>
);

Neutral.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
