import { Smile } from "../../smilies/smile";
import { Neutral } from "../../smilies/neutral";
import { Evil } from "../../smilies/evil";
import { Arrow } from "../../components/arrow";
import classNames from "classnames";
import { Info } from "../../components/info";
import { OverviewIcon } from "../../components/overview-icon";
import { DetailIcon } from "../../components/detail-icon";
import { CloseIcon } from "../../components/close-icon";

export const Page1 = () => {
  return (
    <div className="mx-auto flex h-full max-w-2xl flex-col justify-center">
      <div className="mb-16 flex flex-row items-center justify-items-start">
        <Info className="h-auto max-w-[7rem]" />
        <p className="ml-8 text-6xl font-bold text-black">Anleitung</p>
      </div>
      <div className="mb-16 flex flex-row items-center justify-items-start">
        <OverviewIcon className="h-auto max-w-[7rem]" />
        <p className="ml-8 text-6xl font-bold text-black">Wortübersicht</p>
      </div>
      <div className="mb-16 flex flex-row items-center justify-items-start">
        <DetailIcon className="h-auto max-w-[7rem]" />
        <p className="ml-8 text-6xl font-bold text-black">Einzelansicht</p>
      </div>
      <div className="mb-16 flex flex-row items-center justify-items-start">
        <CloseIcon className="h-auto max-w-[7rem]" />
        <p className="ml-8 text-6xl font-bold text-black">Spiel beenden</p>
      </div>
    </div>
  );
};
