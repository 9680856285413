import PropTypes from "prop-types";
import classNames from "classnames";

export const HandIcon = (props) => {
  return (
    <svg
      className={classNames({
        [props.className]: props.className,
      })}
      onClick={props.onClick}
      width="118"
      height="118"
      viewBox="0 0 118 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M87.6246 32.5811C86.8143 31.1743 85.6765 29.9846 84.31 29.1157C82.9436 28.2467 81.3901 27.7248 79.7848 27.5954C78.1794 27.4659 76.5709 27.7329 75.0993 28.3731C73.6278 29.0132 72.3377 30.0071 71.3415 31.2682C70.0998 30.1437 68.5992 29.3497 66.9786 28.9599C65.3581 28.5702 63.6701 28.5971 62.071 29.0384C60.472 29.4796 59.0136 30.3209 57.831 31.4842C56.6485 32.6475 55.7801 34.0952 55.3062 35.6931C53.8787 34.9178 52.2875 34.5047 50.6721 34.4901C49.0566 34.4755 47.4663 34.8599 46.0406 35.6096C44.615 36.3592 43.3975 37.4512 42.4952 38.7898C41.5928 40.1283 41.033 41.6724 40.8649 43.2868L19.826 6.84626C19.228 5.75267 18.4199 4.78818 17.4491 4.00946C16.4784 3.23074 15.3646 2.65352 14.1733 2.31175C12.982 1.96998 11.7372 1.87054 10.5121 2.01929C9.28697 2.16805 8.10624 2.56199 7.03936 3.17796C5.97248 3.79392 5.04095 4.61949 4.29956 5.6061C3.55817 6.59271 3.0219 7.72043 2.72223 8.92302C2.42256 10.1256 2.36555 11.3788 2.55458 12.6088C2.7436 13.8389 3.1748 15.021 3.82289 16.0857L27.8825 57.7581L14.8031 52.7624C13.6395 52.3103 12.3955 52.1137 11.1552 52.1859C9.91482 52.2582 8.70703 52.5975 7.61343 53.1811C6.51982 53.7646 5.56585 54.5787 4.816 55.5685C4.06615 56.5582 3.53788 57.7005 3.26688 58.9181C2.99588 60.1357 2.98844 61.4003 3.24512 62.6264C3.5018 63.8525 4.01663 65.0115 4.75474 66.0252C5.49285 67.0389 6.43709 67.8837 7.52365 68.5023C8.61021 69.121 9.81376 69.4992 11.053 69.6114C13.6627 70.3308 17.9516 71.8902 20.8613 74.9124C25.2778 79.5051 33.2977 90.8385 37.1633 92.541C41.3559 94.3513 45.6936 95.7922 50.1272 96.8476C54.6398 97.9385 62.4336 98.9147 62.4336 98.9147L69.2573 115.706C69.2573 115.706 83.9215 116.65 99.0647 106.36C114.208 96.0693 115.92 84.3612 115.92 84.3612L103.721 68.5303C103.721 68.5303 103.492 59.371 99.2223 50.7532C94.9527 42.1353 91.8857 37.4242 87.6246 32.5811Z"
        fill="#D5654D"
        stroke="#0E0E10"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

HandIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
