import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import FazitVideo from "url: ../../../static/AMPEL_FAZIT.m4v";
import { Button } from "../components/button";

export const Fazit = () => {
  const [mode, setMode] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setMode("buttons");
    }, 1000 * 16);
  }, []);

  return (
    <div className="h-screen">
      <div className="relative flex h-screen items-center justify-center overflow-hidden">
        <video
          autoPlay
          muted
          className="absolute z-10 h-full w-full object-cover"
        >
          <source src={FazitVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      {mode === "buttons" ? (
        <div className="absolute top-[46%] z-50 flex w-full items-center justify-center space-x-28">
          <Button
            onClick={() => navigate("/spielen")}
            className="mx-0 bg-freiburg-white hover:bg-freiburg-white"
          >
            weiterspielen
          </Button>
          <Button
            onClick={() => navigate("/")}
            className="mx-0 bg-freiburg-white hover:bg-freiburg-white"
          >
            Spiel beenden
          </Button>
        </div>
      ) : null}
    </div>
  );
};
