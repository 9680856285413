import { Arrow } from "../../components/arrow";
import { Button } from "../../components/button";
import { HandIcon } from "../../components/hand-icon";
import StartImage from "../../../static/bubble-smiley-wrong-kitisch.svg";
import { SmileyNeutral } from "../../game/reactions/smiley-neutral";

export const Page4 = () => {
  return (
    <div>
      <header className="py-16 px-40 pb-8">
        <p className="mb-8 items-baseline justify-center text-center text-6xl font-bold leading-tight">
          <h3 className="mt-4 mb-8 inline pr-6 text-center font-Monument text-8xl font-bold leading-tight text-freiburg-red">
            5&nbsp;
          </h3>
          Neugierig? Wische auf dem Bildschirm nach links, dann kommt ein neues
          Wort.
        </p>
      </header>

      <div className="mx-auto max-w-[53.5rem] border-2 border-freiburg-black px-20 pb-10 pt-4">
        <div className="relative mt-20 mb-6 flex items-center justify-center">
          <img
            src={StartImage}
            className="absolute left-[calc(50%+15px)] -top-[80px] max-w-[18rem]"
          />
          <SmileyNeutral className="mx-2 h-auto max-w-[5.5rem]" />
        </div>

        <div className="relative flex items-center justify-between">
          <Arrow fill="#ecece7" direction="left" className="max-w-[3rem]" />
          <div>
            <div className="rounded-2xl border-2 border-black px-24 py-16 pt-8 text-center">
              <span className="mb-10 block font-Monument text-[1.75rem] font-extrabold">
                Begriff
              </span>
              <span className="mb-4 block text-[1rem]">
                Hier steht eine Info zum Begriff
              </span>
            </div>
            <div className="mt-6 flex px-2">
              <Button className="min-w-[11rem] rounded-2xl border-2 bg-freiburg-white p-2 px-6 pt-2 font-Monument text-xl">
                nochmal
              </Button>
              <Button className="min-w-[11rem] rounded-2xl border-2 bg-freiburg-white p-2 px-6 pt-2 font-Monument text-xl">
                weiter
              </Button>
            </div>
          </div>

          <Arrow
            fill="#D5654D"
            className="top-30 absolute right-32 max-w-[5.5rem] rotate-180"
          />
          <HandIcon className="absolute top-[155px] right-12 max-w-[6rem] rotate-[60deg]" />

          <Arrow fill="#ecece7" className="max-w-[3rem]" />
        </div>
      </div>
    </div>
  );
};
