import { useLocation, useNavigate } from "react-router-dom";
import { Info } from "../components/info";
import { OverviewIcon } from "../components/overview-icon";
import { DetailIcon } from "../components/detail-icon";
import { CloseIcon } from "../components/close-icon";

export const Header = () => {
  let navigate = useNavigate();
  let location = useLocation();

  return (
    <nav className="flex justify-between px-14 pt-10">
      <div className="flex">
        <Info
          className="mr-8 max-w-[8rem] cursor-pointer"
          onClick={() => navigate("/spielen/hilfe")}
        />

        {location.pathname === "/spielen" ? (
          <OverviewIcon
            className="max-w-[8rem] cursor-pointer"
            onClick={() => navigate("/spielen/uebersicht")}
          />
        ) : (
          <DetailIcon
            className="max-w-[8rem] cursor-pointer"
            onClick={() => navigate("/spielen")}
          />
        )}
      </div>

      <CloseIcon
        className="max-w-[7rem] cursor-pointer"
        onClick={() => navigate("/exit")}
      />
    </nav>
  );
};
