import PropTypes from "prop-types";

export const SmileyEvil = (props) => {
  return (
    <svg
      onClick={props.onClick}
      className={props.className ?? props.className}
      width="237"
      height="237"
      viewBox="0 0 237 237"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M118.163 233.519C181.727 233.519 233.255 181.991 233.255 118.427C233.255 54.8637 181.727 3.33521 118.163 3.33521C54.5995 3.33521 3.07104 54.8637 3.07104 118.427C3.07104 181.991 54.5995 233.519 118.163 233.519Z"
        fill="#D5654D"
        stroke="#0E0E10"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M97.7927 81.9174C97.7927 84.8249 96.6377 87.6134 94.5817 89.6693C92.5258 91.7252 89.7374 92.8803 86.8298 92.8803C83.9223 92.8803 81.1338 91.7252 79.0778 89.6693C77.0219 87.6134 75.8669 84.8249 75.8669 81.9174"
        fill="#D5654D"
      />
      <path
        d="M97.7927 81.9174C97.7927 84.8249 96.6377 87.6134 94.5817 89.6693C92.5258 91.7252 89.7374 92.8803 86.8298 92.8803C83.9223 92.8803 81.1338 91.7252 79.0778 89.6693C77.0219 87.6134 75.8669 84.8249 75.8669 81.9174"
        stroke="#0E0E10"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M160.482 81.9174C160.531 83.3872 160.284 84.852 159.756 86.2244C159.228 87.5969 158.428 88.849 157.406 89.9062C156.384 90.9634 155.159 91.8041 153.805 92.3782C152.452 92.9524 150.996 93.2483 149.525 93.2483C148.055 93.2483 146.599 92.9524 145.245 92.3782C143.891 91.8041 142.666 90.9634 141.644 89.9062C140.622 88.849 139.823 87.5969 139.294 86.2244C138.766 84.852 138.519 83.3872 138.568 81.9174"
        fill="#D5654D"
      />
      <path
        d="M160.482 81.9174C160.531 83.3872 160.284 84.852 159.756 86.2244C159.228 87.5969 158.428 88.849 157.406 89.9062C156.384 90.9634 155.159 91.8041 153.805 92.3782C152.452 92.9524 150.996 93.2483 149.525 93.2483C148.055 93.2483 146.599 92.9524 145.245 92.3782C143.891 91.8041 142.666 90.9634 141.644 89.9062C140.622 88.849 139.823 87.5969 139.294 86.2244C138.766 84.852 138.519 83.3872 138.568 81.9174"
        stroke="#0E0E10"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M190.118 186.753C190.118 168.812 182.991 151.607 170.305 138.921C157.619 126.235 140.414 119.108 122.473 119.108C104.533 119.108 87.3271 126.235 74.6412 138.921C61.9554 151.607 54.8286 168.812 54.8286 186.753"
        stroke="#0E0E10"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

SmileyEvil.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
