import classNames from "classnames";
import PropTypes from "prop-types";

export const OverviewIcon = (props) => {
  return (
    <svg
      className={classNames({
        [props.className]: props.className,
      })}
      onClick={props.onClick}
      width="135"
      height="135"
      viewBox="0 0 135 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.041 131.082C102.41 131.082 131.082 102.41 131.082 67.041C131.082 31.6721 102.41 3 67.041 3C31.6721 3 3 31.6721 3 67.041C3 102.41 31.6721 131.082 67.041 131.082Z"
        fill="#0E0E10"
        stroke="#0E0E10"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <rect x="26" y="39" width="37" height="24" rx="3" fill="#ECECE7" />
      <rect x="72" y="39" width="37" height="24" rx="3" fill="#ECECE7" />
      <rect x="26" y="72" width="37" height="24" rx="3" fill="#ECECE7" />
      <rect x="72" y="72" width="37" height="24" rx="3" fill="#ECECE7" />
    </svg>
  );
};

OverviewIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
