import PropTypes from "prop-types";

export const SmileyOk = (props) => {
  return (
    <svg
      onClick={props.onClick}
      className={props.className ?? props.className}
      width="237"
      height="237"
      viewBox="0 0 237 237"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M118.163 233.519C181.727 233.519 233.255 181.991 233.255 118.427C233.255 54.8634 181.727 3.33496 118.163 3.33496C54.5995 3.33496 3.07104 54.8634 3.07104 118.427C3.07104 181.991 54.5995 233.519 118.163 233.519Z"
        fill="#46877F"
        stroke="#0E0E10"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M97.7927 81.917C97.7927 84.8245 96.6377 87.613 94.5817 89.6689C92.5258 91.7249 89.7374 92.8799 86.8298 92.8799C83.9223 92.8799 81.1338 91.7249 79.0778 89.6689C77.0219 87.613 75.8669 84.8245 75.8669 81.917"
        fill="#46877F"
      />
      <path
        d="M97.7927 81.917C97.7927 84.8245 96.6377 87.613 94.5817 89.6689C92.5258 91.7249 89.7374 92.8799 86.8298 92.8799C83.9223 92.8799 81.1338 91.7249 79.0778 89.6689C77.0219 87.613 75.8669 84.8245 75.8669 81.917"
        stroke="#0E0E10"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M160.482 81.917C160.531 83.3868 160.284 84.8516 159.756 86.224C159.228 87.5965 158.428 88.8486 157.406 89.9058C156.384 90.963 155.159 91.8037 153.805 92.3779C152.452 92.952 150.996 93.248 149.525 93.248C148.055 93.248 146.599 92.952 145.245 92.3779C143.891 91.8037 142.666 90.963 141.644 89.9058C140.622 88.8486 139.823 87.5965 139.294 86.224C138.766 84.8516 138.519 83.3868 138.568 81.917"
        fill="#46877F"
      />
      <path
        d="M160.482 81.917C160.531 83.3868 160.284 84.8516 159.756 86.224C159.228 87.5965 158.428 88.8486 157.406 89.9058C156.384 90.963 155.159 91.8037 153.805 92.3779C152.452 92.952 150.996 93.248 149.525 93.248C148.055 93.248 146.599 92.952 145.245 92.3779C143.891 91.8037 142.666 90.963 141.644 89.9058C140.622 88.8486 139.823 87.5965 139.294 86.224C138.766 84.8516 138.519 83.3868 138.568 81.917"
        stroke="#0E0E10"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M190.118 119.108C190.118 137.048 182.991 154.254 170.305 166.94C157.619 179.626 140.414 186.752 122.473 186.752C104.533 186.752 87.3271 179.626 74.6412 166.94C61.9554 154.254 54.8286 137.048 54.8286 119.108"
        stroke="#0E0E10"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

SmileyOk.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
